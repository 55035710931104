import {
  Alert,
  Card,
  Col,
  DatePicker,
  Image,
  Modal,
  Row,
  Form,
  Select,
  Input,
  Button,
  InputNumber,
  Upload,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { products, store } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";
import {
  RightCircleOutlined,
  UploadOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { upload } from "@testing-library/user-event/dist/upload";
import moment from "moment";

const { Option } = Select;

function EditProduct() {
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [uploadImages, setUploadImages] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [productCategories, setProductCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [specifications, setSpecifications] = useState([]);

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const formRef = React.createRef();

  const onFinish = (values) => {
    console.log(values);
  };

  const handleFormSuccess = (values) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("product_id", product.id);
    formData.append("title", values.title);
    formData.append(
      "store_id",
      JSON.parse(localStorage.getItem("user")).user_vendor[0].id
    );
    formData.append("category_id", values.category_id);
    formData.append("sub_category_id", values.subcategory_id);
    formData.append("mrp", values.mrp);
    formData.append("tax", values.tax);
    formData.append("price", values.price);
    formData.append("description", values.description);
    formData.append("length", values.length);
    formData.append("breadth", values.breadth);
    formData.append("hiegth", values.height);
    formData.append("weigth", values.weight);
    formData.append("commission_amount", values.commision);
    formData.append("return_period", values.return);
    formData.append("warranty_period", values.warranty);

    if (values.expires) {
      formData.append(
        "expiry_date",
        moment(values.expires).format("YYYY-MM-DD")
      );
    }

    formData.append("long_description", values.long_description);
    formData.append("delivery_charges", values.delivery_charges);
    formData.append("cod", values.cod === true ? "True" : "False");
    formData.append("in_stock", "True");
    formData.append("active", "True");
    formData.append(
      "specification",
      JSON.stringify({ specification: specifications })
    );

    thumbnail && formData.append("thumbnail", thumbnail);

    products.edit(formData).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success === true) {
        createAlert("success", "Successfully edited product.");
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleFormError = (values) => {
    console.log(values);
  };

  useEffect(() => {
    setLoading(true);
    if (searchParams.get("id")) {
      async function getData() {
        await store
          .get_categories()
          .then((res) => {
            setLoading(false);
            console.log(res.data);
            setProductCategories(res.data);
          })
          .catch((err) => {
            createAlert("error", err.message);
          });
        await products.getSingle(searchParams.get("id")).then((res) => {
          console.log(res);
          setLoading(false);
          if (res.success) {
            setProduct(res.data);
            setProductImages(res.data.product_image);
            form.setFieldsValue({
              title: res.data.title,
              stock_qty: res.data.stock_qty,
              mrp: res.data.mrp,
              tax: res.data.tax,
              price: res.data.price,
              breadth: res.data.breadth,
              length: res.data.length,
              weight: res.data.weigth,
              height: res.data.hiegth,
              commision: res.data.commission_amount,
              return: res.data.return_period,
              warranty: res.data.warranty_period,
              expires: res.data.expiry_date && moment(res.data.expiry_date),

              category_id: res.data.category.title,
              subcategory_id: res.data.sub_category.title,
              description: res.data.description,
              long_description: res.data.long_description,
              cod: res.data.cod,
              delivery_charges: res.data.delivery_charges,
            });
            setSpecifications(JSON.parse(res.data.specification).specification);
          } else {
            createAlert("error", res.message);
          }
        });
      }
      getData();
    } else {
      createAlert("error", "Product not found.");
    }
  }, [searchParams, form]);

  const fetchProduct = async () => {
    await products.getSingle(searchParams.get("id")).then((res) => {
      console.log(res);
      setLoading(false);
      if (res.success) {
        setProduct(res.data);
        setProductImages(res.data.product_image);

        form.setFieldsValue({
          // title: res.data.title,
          // stock_qty: res.data.stock_qty,
          // mrp: res.data.mrp,
          // price: res.data.price,
          // branch_id: res.data.branch.branch,
          // category_id: res.data.product_category.title,
          // description: res.data.description,
        });
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleImageUpload = () => {
    if (uploadImages.length > 0) {
      setLoading(true);

      let formData = new FormData();
      formData.append("product_id", product.id);

      for (let item of uploadImages) {
        formData.append("image", item.originFileObj);
      }

      products.uploadImages(formData).then((res) => {
        setLoading(false);
        if (res.success) {
          createAlert("success", "Images uploaded");
          fetchProduct();
        } else {
          createAlert("error", res.message);
        }
      });
    } else {
      createAlert("error", "Please select the images");
    }
  };

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleFieldUpdate = (index, e) => {
    let newArray = [...specifications];
    let el = newArray[index];
    el[e.target.name] = e.target.value;

    setSpecifications(newArray);
    console.log(specifications);
    // let oldArray = specifications;
  };

  const handleRemoveItem = (e) => {
    let temp = [...specifications];
    temp.splice(e, 1);
    setSpecifications(temp);
  };

  const handleCategoryChange = (e) => {
    let sub_categories = productCategories.find((x) => x.id === e);
    // console.log(sub_categories.sub_category);
    setSubCategories(sub_categories.sub_category);
  };

  const handleImageDelete = async () => {
    setLoading(true);
    setModalVisible(false);
    await products.deleteImage(deleteId).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        createAlert("success", "Image successfully deleted.");
        let remaining = productImages.filter((e) => {
          return e.id !== deleteId;
        });
        setProductImages(remaining);
      } else {
        createAlert("error", res.message);
      }
    });
  };
  function SpecsBox(index) {
    return (
      <div className="flex items-center w-full   h-full">
        <PlusCircleOutlined
          className="text-lg  mr-4 "
          onClick={() => {
            setSpecifications((specifications) => [
              ...specifications,
              {
                title: "",
                description: "",
              },
            ]);
          }}
        />
        {index !== 0 ? (
          <DeleteOutlined
            onClick={(item, index) => {
              handleRemoveItem(index);
            }}
            className="text-lg"
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <>
      {product && !loading ? (
        <>
          <PageBreadcrumb
            items={[
              { link: "/", title: "Home" },
              { link: "/products", title: "Products" },
              { title: "Edit" },
              { title: product.title },
            ]}
          />
          <div className="d-flex justify-space-between align-content-center align-center">
            <Header title={product.title} />
          </div>
          <Card title="Product Details" bordered={true}>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={handleFormSuccess}
              onFinishFailed={handleFormError}
              autoComplete="off"
              form={form}
              ref={formRef}
            >
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item
                    className="hide-astricks"
                    label="Select Product Category"
                    name="category_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select main category.",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select category"
                      onChange={(e) => {
                        handleCategoryChange(e);
                      }}
                    >
                      {productCategories &&
                        productCategories.map((item, index) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    className="hide-astricks"
                    label="Select Product Sub Category"
                    name="subcategory_id"
                    rules={[
                      {
                        required: true,
                        message: "Please select sub category.",
                      },
                    ]}
                  >
                    <Select placeholder="Select category">
                      {subCategories &&
                        subCategories.map((item, index) => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.title}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                  <Form.Item
                    className="hide-astricks"
                    label="Product name"
                    name="title"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter product name.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter product name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} md={8}>
                  <Form.Item
                    className="hide-astricks"
                    label="MRP"
                    name="mrp"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter MRP.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter MRP"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={8}>
                  <Form.Item
                    className="hide-astricks"
                    label="Selling price"
                    name="price"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter selling price.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter price"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    className="hide-astricks"
                    label="Tax"
                    name="tax"
                    hasFeedback
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Tax"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col xs={24} lg={4}>
                  <Form.Item
                    className="hide-astricks"
                    label="Delivery charges"
                    name="delivery_charges"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter delivery charges.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Delivery charges"
                    />
                  </Form.Item>
                </Col>
                <Col lg={4}>
                  <Form.Item
                    className="hide-astricks"
                    label="Cash on delivery"
                    name="cod"
                    hasFeedback
                  >
                    <Switch defaultChecked={product.cod} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Form.Item
                    className="hide-astricks"
                    label="Commision"
                    name="commision"
                    hasFeedback
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Enter Commision for this product "
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} lg={4}>
                  <Form.Item
                    className="hide-astricks "
                    label="Expiry Date"
                    name="expires"
                    hasFeedback
                  >
                    <DatePicker className="w-full" />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={6}>
                  <Form.Item
                    className="hide-astricks"
                    label="Return Period (In Days)"
                    name="return"
                    hasFeedback
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Enter return period in days"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6}>
                  <Form.Item
                    className="hide-astricks"
                    label="Warranty Period (In Days)"
                    name="warranty"
                    hasFeedback
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Enter warranty period in days"
                    />
                    <Input placeholder="Enter warranty period in days" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                className="hide-astricks"
                label="Product Short Description"
                name="description"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter short description.",
                  },
                ]}
              >
                <Input placeholder="Enter Short Description" />
              </Form.Item>
              <Form.Item
                className="hide-astricks"
                label="Product Long Description"
                name="long_description"
                hasFeedback
                rules={[
                  { required: true, message: "Please enter long desription." },
                ]}
              >
                <Input.TextArea placeholder="Enter Long Description" />
              </Form.Item>

              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    className="hide-astricks whitespace-nowrap"
                    label="Product Thumbnail"
                    name="thumbnail"
                    hasFeedback
                  >
                    <Upload
                      maxCount={1}
                      accept=".jpg, .png"
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={(e) => {
                        if (e.fileList.length) {
                          setThumbnail(e.fileList[0].originFileObj);
                        }
                      }}
                    >
                      <Button
                        style={{ minWidth: "100%" }}
                        icon={<UploadOutlined />}
                      >
                        Upload Thumbnail
                      </Button>
                    </Upload>
                  </Form.Item>
                  {product.thumbnail ? (
                    <a
                      target="blank"
                      className="mb-3 block"
                      href={`https://backend.dzone22.com${product.thumbnail}`}
                    >
                      View Existing Thumbail
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <div className="flex justify-between">
                <h3 className="text-lg font-semibold">Product Dimensions</h3>
                <div className="sm:hidden block">
                  <PlusCircleOutlined
                    className="text-lg   mb-2 "
                    onClick={() => {
                      setSpecifications((specifications) => [
                        ...specifications,
                        {
                          title: "",
                          description: "",
                        },
                      ]);
                    }}
                  />
                </div>
              </div>

              {/* <hr /> */}
              <Row gutter={12}>
                <Col xs={24} lg={6}>
                  <Form.Item
                    className="hide-astricks"
                    label="Length"
                    name="length"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter Length.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter length"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={6}>
                  <Form.Item
                    className="hide-astricks"
                    label="Breadth"
                    name="breadth"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter breadth.",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter breadth"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={6}>
                  <Form.Item
                    className="hide-astricks"
                    label="Height"
                    name="height"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter height.",
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter Height"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6}>
                  <Form.Item
                    className="hide-astricks"
                    label="Weight"
                    name="weight"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter weight.",
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter weight"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-between">
                <h3 className="text-lg font-semibold">
                  Product Specifications
                </h3>
                <div className="sm:hidden block">
                  <PlusCircleOutlined
                    className="text-lg   mb-2 "
                    onClick={() => {
                      setSpecifications((specifications) => [
                        ...specifications,
                        {
                          title: "",
                          description: "",
                        },
                      ]);
                    }}
                  />
                </div>
              </div>
              <hr />

              <div className="mt-4">
                {specifications.map((item, index) => {
                  return (
                    <Row
                      className="border border-solid mt-3 border-slate-200"
                      key={index}
                    >
                      <Col
                        md={24}
                        sm={24}
                        xs={24}
                        lg={11}
                        className="sm:border-r border-b border-slate-200 p-2"
                      >
                        <div className=" flex items-center h-full">
                          <input
                            type="text"
                            value={item.title}
                            style={{ width: "100%" }}
                            name="title"
                            className="focus:outline-0"
                            placeholder="Enter title"
                            onChange={(e) => {
                              handleFieldUpdate(
                                specifications.indexOf(item),
                                e
                              );
                            }}
                          />
                        </div>
                      </Col>
                      <Col
                        md={24}
                        xs={24}
                        sm={24}
                        lg={11}
                        className="sm:border-r  border-b border-slate-200 p-2"
                      >
                        <div className=" flex items-center h-full">
                          <textarea
                            type="text"
                            value={item.description}
                            style={{ width: "100%" }}
                            name="description"
                            className="focus:outline-0"
                            placeholder="Enter Description"
                            onChange={(e) => {
                              handleFieldUpdate(
                                specifications.indexOf(item),
                                e
                              );
                            }}
                          ></textarea>
                        </div>
                      </Col>
                      <Col lg={2} xs={0} sm={0} className="pl-3">
                        {SpecsBox(index)}
                      </Col>
                    </Row>
                  );
                })}
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="mt-4"
                  loading={loading}
                >
                  Edit
                  <RightCircleOutlined />
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </>
      ) : (
        ""
      )}

      {!loading ? (
        <Card
          title="Product Images"
          loading={loading}
          className="mt-8"
          bordered={true}
        >
          {/* card grid for sm size */}
          {productImages?.map((e) => (
            <>
              <Card.Grid
                className="sm:hidden block"
                bordered={false}
                // hoverable={false}
                style={{
                  width: "100%",

                  borderWidth: "0px",
                  borderColor: "white",
                }}
              >
                {Images(e)}
              </Card.Grid>
            </>
          ))}
          {productImages && productImages.length > 0 ? (
            <>
              <div className="sm:flex hidden flex-row mb-5">
                {productImages.map((e) => Images(e))}
              </div>
            </>
          ) : (
            <Alert
              message="No images available."
              type="warning"
              className="mb-5"
            />
          )}

          <Upload
            beforeUpload={() => {
              return false;
            }}
            accept=".jpg,.png"
            multiple
            onChange={(e) => {
              if (e.fileList.length > 0) {
                console.log(e.fileList);
                setUploadImages(e.fileList);
              }
            }}
          >
            <Button style={{ minWidth: "100%" }} icon={<UploadOutlined />}>
              Select Images to upload
            </Button>
          </Upload>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="mt-4"
            onClick={handleImageUpload}
          >
            Upload Images
            <RightCircleOutlined />
          </Button>
        </Card>
      ) : (
        ""
      )}
      <Modal
        title="Modal"
        visible={modalVisible}
        onOk={handleImageDelete}
        onCancel={hideModal}
        okButtonProps={{ type: "danger" }}
        okText="Delete Image"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete the image ?</p>
      </Modal>
    </>
  );

  function Images(e) {
    return (
      <div
        key={e.id}
        style={{
          position: "relative",
        }}
      >
        <Image
          width={150}
          rootClassName="mr-5"
          style={{ border: "1px solid #eee" }}
          src={`https://backend.dzone22.com/${e.image}`}
        ></Image>{" "}
        <DeleteOutlined
          onClick={() => {
            console.log(e.id);
            setDeleteId(e.id);
            setModalVisible(true);
          }}
          style={{
            position: "absolute",
            background: "rgba(238,238,238,.68)",
            cursor: "pointer",
            padding: 10,
            borderRadius: 500,
            top: 5,
            right: 25,
          }}
        />
      </div>
    );
  }

  function Images(e) {
    return (
      <div
        key={e.id}
        style={{
          position: "relative",
        }}
      >
        <Image
          width={150}
          rootClassName="mr-5"
          style={{ border: "1px solid #eee" }}
          src={`https://backend.dzone22.com/${e.image}`}
        ></Image>{" "}
        <DeleteOutlined
          onClick={() => {
            console.log(e.id);
            setDeleteId(e.id);
            setModalVisible(true);
          }}
          style={{
            position: "absolute",
            background: "rgba(238,238,238,.68)",
            cursor: "pointer",
            padding: 10,
            borderRadius: 500,
            top: 5,
            right: 25,
          }}
        />
      </div>
    );
  }
}

export default EditProduct;
