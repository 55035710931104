import { PlusCircleOutlined, EyeFilled, EditFilled } from "@ant-design/icons";
import {
  Button,
  Table,
  Space,
  Row,
  Switch,
  Col,
  Modal,
  InputNumber,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { products } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";

function ManageProducts() {
  const [productsList, setProductsList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [stocksModal, setStocksModal] = useState(false);
  const [productId, setProductId] = useState();

  useEffect(() => {
    setLoading(true);
    async function getData() {
      return await products
        .get(localStorage.getItem("store"))
        .then((res) => {
          setLoading(false);
          console.log(res);
          setProductsList(res.data);
        })
        .catch((err) => {
          createAlert("error", err.message);
        });
    }
    getData();
  }, []);

  const getData = async () => {
    await products
      .get(localStorage.getItem("store"))
      .then((res) => {
        setLoading(false);
        console.log(res);
        setProductsList(res.data);
      })
      .catch((err) => {
        createAlert("error", err.message);
      });
  };

  const handleStatusChange = (id) => {
    products
      .changeStatus({
        product_id: id,
      })
      .then(async (res) => {
        console.log(res);
        if (res.success) {
          setLoading(true);
          await getData();
          setLoading(false);
        }
      });
  };

  const handleStockChange = (id) => {
    products
      .changeStockStatus({
        product_id: id,
      })
      .then(async (res) => {
        console.log(res);
        if (res.success) {
          setLoading(true);
          await getData();
          setLoading(false);
        }
      });
  };

  const showStocksModal = (id) => {
    setProductId(id);
    setStocksModal(true);
  };

  const columns = [
    {
      title: "ID",
      key: 5,
      dataIndex: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: 0,
      sorter: (a, b) => {
        if (a.title.toUpperCase() < b.title.toUpperCase) {
          return -1;
        }
        if (a.title.toUpperCase() > b.title.toUpperCase) {
          return 1;
        }

        // names must be equal
        return 0;
      },
      sortDirections: ["ascend"],
    },

    {
      title: "MRP",
      dataIndex: "mrp",
      key: 1,
    },
    {
      title: "Selling price",
      dataIndex: "price",
      key: 2,
      sorter: (a, b) => new Date(a.price) - new Date(b.price),
      sortDirections: ["descend"],
    },
    {
      title: "Stock Qty",
      key: "stock_qty",
      dataIndex: "stock_qty",
    },
    {
      title: " In Stock",
      key: "in_stock",
      dataIndex: "in_stock",
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>
              <Switch
                checked={record.in_stock}
                onChange={(e) => {
                  console.log(record.active);
                  handleStockChange(record.id);
                }}
              />
            </Space>
          </Row>
        </Space>
      ),
    },
    // {
    //   title: "Active",
    //   key: "id",
    //   dataIndex: "active",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Row>
    //         <Space>
    //           <Switch
    //             checked={record.active}
    //             onChange={(e) => {
    //               console.log(record.active);
    //               handleStatusChange(record.id);
    //             }}
    //           />
    //         </Space>
    //       </Row>
    //     </Space>
    //   ),
    // },

    {
      title: "Actions",
      key: 4,

      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>
              <Button
                title="Add stocks"
                onClick={(e) => {
                  // navigate(`/products/edit?id=${record.id}`);
                  showStocksModal(record.id);
                  console.log(record.id);
                }}
              >
                <PlusCircleOutlined /> Add
              </Button>
              <Button
                title="View"
                onClick={(e) => {
                  navigate(`/products/view?id=${record.id}`);
                  console.log(record.id);
                }}
              >
                <EyeFilled /> View
              </Button>
              <Button
                title="Edit"
                onClick={(e) => {
                  navigate(`/products/edit?id=${record.id}`);
                  console.log(record.id);
                }}
              >
                <EditFilled /> Edit
              </Button>
            </Space>
          </Row>
        </Space>
      ),
    },
  ];
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setConfirmLoading(true);
    const data = new FormData();
    data.append("product_id", productId);
    data.append("qty", values.quantity);
    products.add_stocks(data).then((res) => {
      setConfirmLoading(false);
      console.log(res);
      if (res.success === true) {
        createAlert("success", res.message);
        setStocksModal(false);
        form.resetFields();
        getData();
      } else {
        createAlert("error", res.message);
      }
    });
    // message.success("Submit success!");
  };

  const onFinishFailed = () => {
    // message.error("Submit failed!");
  };

  return (
    <>
      <Modal
        title="Add Stocks"
        visible={stocksModal}
        footer={false}
        centered
        onCancel={() => setStocksModal(false)}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="quantity"
                label="Quantity"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Enter quantity"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={confirmLoading}
                  >
                    Add Stocks
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <PageBreadcrumb
        items={[{ link: "/", title: "Home" }, { title: "Products" }]}
      />
      <Row justify="space-between" align="middle">
        <Col>
          <div className="flex whitespace-nowrap justify-space-between align-content-center align-center my-5">
            <h6 className="font-bold text-xl"> Manage Products </h6>
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            className="flex mb-3 sm:mb-0"
            onClick={() => {
              navigate("/products/create");
            }}
            icon={<PlusCircleOutlined />}
          >
            Create New Product
          </Button>
        </Col>
      </Row>
      <Table
        scroll={{ x: "500px" }}
        loading={loading}
        pagination={{ pageSize: 10 }}
        dataSource={productsList}
        rowKey={"id"}
        columns={columns}
      />
    </>
  );
}

export default ManageProducts;
