import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Radio,
  Checkbox,
  Button,
  InputNumber,
  DatePicker,
  Steps,
  Upload,
  Select,
} from "antd";
import { RightCircleOutlined, UploadOutlined } from "@ant-design/icons";

import {
  api_submitShop,
  get_shopCategories,
  api_getCategories,
  api_checkMobile,
  api,
} from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";

import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

const { Step } = Steps;
const { Option } = Select;

function Register() {
  const [searchParams] = useSearchParams();
  const [referred, setReferred] = useState(null);
  const [cities, setCities] = useState();
  const navigate = useNavigate();

  // Checking if link has
  useEffect(() => {
    if (searchParams.get("link")) {
      setReferred(searchParams.get("link"));
    } else {
      setRefferalVisible(true);
    }
  }, [referred, searchParams]);

  useEffect(() => {
    api.get_cities().then((res) => {
      setCities(res.data.data);
      console.log("cities", res.data.data);
    });
  }, []);

  // States

  const [mainCategories, setMainCategories] = useState([]);
  const [refferalVisible, setRefferalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [aadhar, setAadhar] = useState(null);
  const [pan, setPan] = useState(null);
  const [gst, setGst] = useState(null);
  const [generalFormValues, setGeneralFormValues] = useState({});

  const [areaList, setAreaList] = useState(null);

  //   Form handling
  const handleGeneralFormSuccess = (values) => {
    setLoading(true);
    api_checkMobile(values.mobile).then((res) => {
      console.log(res.data);
      setLoading(false);
      if (!res.data.success) {
        createAlert("error", res.data.message);
      } else {
        setStep(1);
        setGeneralFormValues(values);
      }
    });
  };
  const handleGeneralFormError = (error) => {
    console.log(error);
  };

  const handleCitySelect = (cityId) => {
    let areas = cities.find((e) => e.id === cityId);
    setAreaList(areas.city);
    console.log("areas", areas.city);
  };

  const handleShopFormSuccess = (values) => {
    const formData = new FormData();

    // general form details
    formData.append("first_name", generalFormValues.first_name);
    formData.append("email", generalFormValues.email);
    formData.append("password", generalFormValues.password);
    formData.append("mobile", generalFormValues.mobile);
    formData.append("user_image", pan);
    formData.append("store_document", gst);
    formData.append("store_name", values.store_name);
    formData.append("store_categor_id", values.store_categor_id);
    formData.append("address", values.address);
    formData.append("city_id", values.city);
    formData.append("role", "vendor");
    formData.append("status", "True");
    formData.append("gender", generalFormValues.gender);

    console.log(values);

    api_submitShop(formData).then((res) => {
      setLoading(true);
      console.log(res);
      if (res.success === true) {
        setLoading(false);
        setStep(2);
        createAlert("success", "Successfully registerd as vendor.");
        navigate("/");
      } else {
        createAlert("error", res.message);
      }
    });
  };
  const handleShopFormError = (values) => {
    // setStep(1);
    const formData = new FormData();
    formData.append("shop_name", values.shop);
    console.log(formData);
    console.log(values.values.shop);
    // console.log(error);
  };
  return (
    <>
      {/* Vendor register form */}
      <div className="container w-100 pt-4">
        <Row justify="center">
          <Col lg={12}>
            <h1 className="text-2xl mt-4 mb-5 font-extrabold">
              Vendor Registration.
            </h1>
            <Steps size="small" current={step} className="pb-10 pt-5">
              <Step
                className="cursor-pointer"
                title={
                  <div className="pt-2.5 sm:pt-0">Enter General Details</div>
                }
                onClick={() => {
                  setStep(0);
                }}
              >
                {" "}
                Details
              </Step>
              <Step
                title={<div className="pt-2.5 sm:pt-0">Enter Shop Details</div>}
              />
              <Step
                title={
                  <div className="pt-2.5 sm:pt-0">Complete Registration</div>
                }
              />
            </Steps>
          </Col>
        </Row>
      </div>
      {step === 0 ? (
        <div className="container w-100">
          <Row justify="center">
            <Col lg={12}>
              <h2> General Details </h2>
              <div className="form-wrapper">
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={handleGeneralFormSuccess}
                  onFinishFailed={handleGeneralFormError}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Enter First name"
                    className="hide-astricks"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your firsname.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter first name" />
                  </Form.Item>
                  <Form.Item
                    label="Enter Email Address"
                    name="email"
                    type="email"
                    className="hide-astricks"
                    hasFeedback
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Please enter your correct email address.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter email address" />
                  </Form.Item>
                  <Form.Item
                    label="Enter Password"
                    className="hide-astricks"
                    name="password"
                    type="password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password.",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter password" />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    className="hide-astricks"
                    name="confirmPassword"
                    type="password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm your password" />
                  </Form.Item>
                  <Form.Item
                    className="hide-astricks"
                    label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        type: "number",
                        required: true,
                        message: "Please enter your number!",
                      },
                    ]}
                  >
                    <InputNumber
                      type="number"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Enter your mobile number"
                    />
                  </Form.Item>

                  <Form.Item
                    className="hide-astricks"
                    label="Gender"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select your gender!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="male">Male</Radio>
                      <Radio value="female">Female</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("You Should accept agreement.")
                              ),
                      },
                    ]}
                  >
                    <Checkbox>I accept to the terms &amp; Conditions.</Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="mt-4"
                      loading={loading}
                    >
                      Submit
                      <RightCircleOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="container w-100 pb-5">
          <Row>
            <Col span={12} offset={6}>
              <h2> Enter shop details. </h2>
              <div className="form-wrapper">
                <Form
                  name="basic2"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={handleShopFormSuccess}
                  onFinishFailed={handleShopFormError}
                  autoComplete="off"
                >
                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item
                        label="Store name"
                        className="hide-astricks"
                        name="store_name"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please enter shop name.",
                          },
                        ]}
                      >
                        <Input placeholder="Enter shop name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    className="hide-astricks"
                    label="Store address"
                    name="address"
                    hasFeedback
                    rules={[
                      { required: true, message: "Please enter shop address." },
                    ]}
                  >
                    <Input.TextArea placeholder="Enter Address" />
                  </Form.Item>

                  <Form.Item
                    className="hide-astricks"
                    label="City"
                    name="maincity"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your city!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Enter City"
                      onSelect={(e) => {
                        handleCitySelect(e);
                        console.log(e);
                      }}
                    >
                      {cities &&
                        cities.map((e) => {
                          return (
                            <Option key={e.id} value={e.id}>
                              {e.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="hide-astricks"
                    label="Select Area"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please select area.",
                      },
                    ]}
                  >
                    <Select placeholder="Enter Area">
                      {areaList &&
                        areaList.map((e) => {
                          return (
                            <Option key={e.id} value={e.id}>
                              {e.city}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  <Col span={24}>
                    <Form.Item
                      className="hide-astricks"
                      label="Upload profile picture"
                      name="user_image"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please upload profile picture.",
                        },
                      ]}
                    >
                      <Upload
                        accept=".jpg,.png,.jpeg"
                        beforeUpload={() => {
                          return false;
                        }}
                        maxCount={1}
                        onChange={(e) => {
                          if (e.fileList.length) {
                            setPan(e.fileList[0].originFileObj);
                          }
                        }}
                      >
                        <Button
                          style={{ minWidth: "100%" }}
                          icon={<UploadOutlined />}
                        >
                          Upload Profile picture
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>

                  {/* Documents */}
                  <h3> Upload documents </h3>
                  <hr className="mb-5" />

                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item
                        className="hide-astricks"
                        label="Store Document"
                        name="store_document"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please upload document.",
                          },
                        ]}
                      >
                        <Upload
                          beforeUpload={() => {
                            return false;
                          }}
                          maxCount={1}
                          onChange={(e) => {
                            if (e.fileList.length) {
                              setGst(e.fileList[0].originFileObj);
                            }
                          }}
                        >
                          <Button
                            style={{ minWidth: "100%" }}
                            icon={<UploadOutlined />}
                          >
                            Upload Store Document
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="terms"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("You Should accept agreement.")
                              ),
                      },
                    ]}
                  >
                    <Checkbox>I accept to the terms &amp; Conditions.</Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      className="mt-4"
                    >
                      Register as a vendor
                      <RightCircleOutlined />
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {/* Shop details form */}
    </>
  );
}

export default Register;
