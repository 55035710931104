import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Styles
import "./App.css";
import "./styles/global.scss";
import "antd-css-utilities/utility.min.css";
import "antd/dist/antd.css";

// Route protection
import { ProtectedRoutes } from "./protected/protectedroute";

// Pages
import DashboardLayout from "./layout/DashboardLayout";
import NotFound from "./pages/extras/NotFound";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/register/Register";
// Branches
// Offers
// Wallet
// Digital store
// Products
import AddProduct from "./pages/products/AddProduct";
import EditProduct from "./pages/products/EditProduct";
import ManageProducts from "./pages/products/ManageProducts";
import ViewProduct from "./pages/products/ViewProduct";
// Services
//
import OrdersList from "./pages/orders/OrdersList";
import OrderDetails from "./pages/orders/OrderDetails";
import InvoicePrint from "./components/InvoicePrint";
import ForgetPassword from "./pages/account/ForgetPassword";
import UserDetails from "./pages/account/UserDetails";

const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="forget-password" element={<ForgetPassword />} />

      <Route path="register" element={<Register />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<DashboardLayout />}>
          <Route index element={<Home />} />
          {/* Products */}
          <Route path="products" element={<ManageProducts />} />
          <Route path="products/create" element={<AddProduct />} />
          <Route path="products/view" element={<ViewProduct />} />
          <Route path="products/edit" element={<EditProduct />} />
          {/*  */}
          <Route path="orders">
            <Route path="" element={<OrdersList />} />
            <Route path="view" element={<OrderDetails />} />
            <Route path="print" element={<InvoicePrint />} />
          </Route>
          {/* Profile */}
          <Route path="edit-profile" element={<UserDetails />} />
        </Route>
      </Route>
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
