import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Grid,
  Image,
  Modal,
  Alert,
  Skeleton,
} from "antd";
import { products } from "../../services/vendor.service";
import Header from "../../components/Header";
import moment from "moment";
import { createAlert } from "../../utilities/alert";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import PageBreadcrumb from "../../components/PageBreadcrumb";

function ViewProduct() {
  const [searchParams] = useSearchParams();
  const [product, setProduct] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [specifications, setSpecifications] = useState();

  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();

  useEffect(() => {
    setLoading(true);
    if (searchParams.get("id")) {
      async function getData() {
        return await products.getSingle(searchParams.get("id")).then((res) => {
          console.log(res);
          setLoading(false);
          if (res.success) {
            setProduct(res.data);
            setProductImages(res.data.product_image);
            if (res.data.specification) {
              setSpecifications(
                JSON.parse(res.data.specification).specification
              );
            }
          } else {
            createAlert("error", res.message);
          }
        });
      }
      getData();
    } else {
      createAlert("error", "Product not found.");
    }
  }, [searchParams]);

  const hideModal = () => {
    setModalVisible(false);
  };

  const handleImageDelete = async () => {
    setLoading(true);
    setModalVisible(false);
    await products.deleteImage(deleteId).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        createAlert("success", "Image successfully deleted.");
        let remaining = productImages.filter((e) => {
          return e.id !== deleteId;
        });
        setProductImages(remaining);
      } else {
        createAlert("error", res.message);
      }
    });
  };

  return (
    <>
      {product && !loading ? (
        <>
          <PageBreadcrumb
            items={[
              { link: "/", title: "Home" },
              { link: "/products", title: "Products" },
              { title: product.title },
            ]}
          />
          <div className="d-flex justify-space-between align-content-center align-center">
            <Header title={product.title} />
          </div>
          <Card title="Product Details" bordered={true}>
            <Row gutter={[10, 10]}>
              <Col xs={12} sm={8} md={4}>
                <small> Category </small>
                <h3 className="m-0"> {product.category.title} </h3>
              </Col>
              <Col xs={12} sm={8} md={4}>
                <small>In Stock </small>
                <h3 className="m-0"> {product.in_stock.toString()} </h3>
              </Col>
              <Col xs={12} sm={8} md={4}>
                <small> MRP </small>
                <h3 className="m-0"> ₹ {product.mrp} </h3>
              </Col>
              <Col xs={12} sm={8} md={4}>
                <small> Tax </small>
                <h3 className="m-0"> {product.tax} % </h3>
              </Col>
              <Col xs={12} sm={8} md={4}>
                <small> Selling price </small>
                <h3 className="m-0"> ₹ {product.price} </h3>
              </Col>
              <Col xs={12} sm={8} md={4}>
                <small> Active </small>
                <h3 className="m-0"> {product.active.toString()} </h3>
              </Col>{" "}
              {Boolean(product.weigth) && (
                <Col xs={12} sm={8} md={4}>
                  <small> Weight </small>
                  <h3 className="m-0"> {product.weigth + " kg"} </h3>
                </Col>
              )}
              {Boolean(product.length) && (
                <Col xs={12} sm={8} md={4}>
                  <small> Dimensions </small>
                  <h3 className="m-0">
                    {" "}
                    {`${product.length} x ${product.breadth} x ${product.hiegth} cms`}{" "}
                  </h3>
                </Col>
              )}
              {Boolean(product.warranty_period) && (
                <Col xs={12} sm={8} md={4}>
                  <small> Warranty </small>
                  <h3 className="m-0"> {product.warranty_period} </h3>
                </Col>
              )}
              <Col xs={12} sm={8} md={4}>
                <small> Stock Quantity </small>
                <h3 className="m-0"> {product.stock_qty} </h3>
              </Col>
              <Col
                span={24}
                // xs={12} sm={8} md={24}
                className="mt-5"
              >
                <small> Short Description </small>
                <h3 className="m-0"> {product.description} </h3>
              </Col>
              <Col span={24} className="mt-5">
                <small> Long Description </small>
                <h3 className="m-0"> {product.long_description} </h3>
              </Col>
            </Row>
          </Card>
        </>
      ) : (
        ""
      )}

      {!loading && productImages && productImages.length > 0 ? (
        <Card
          title="Product Images"
          loading={loading}
          className="mt-8"
          bordered={true}
        >
          {productImages.map((e) => (
            <>
              <Card.Grid
                className="sm:hidden block"
                bordered={false}
                // hoverable={false}
                style={{
                  width: "100%",

                  borderWidth: "0px",
                  borderColor: "white",
                }}
              >
                {Images(e)}
              </Card.Grid>
            </>
          ))}
          {
            <div className="sm:flex hidden flex-row mb-5">
              {productImages.map((e) => (
                <div
                  key={e.id}
                  style={{
                    position: "relative",
                  }}
                >
                  <Image
                    width={150}
                    rootClassName="mr-5"
                    style={{ border: "1px solid #eee" }}
                    src={`https://backend.dzone22.com/${e.image}`}
                  ></Image>{" "}
                </div>
              ))}
            </div>
          }
        </Card>
      ) : (
        <Card
          title="Product Images"
          loading={loading}
          className="mt-8"
          bordered={true}
        >
          <Alert message="No images available." type="warning" />
        </Card>
      )}

      <Card className="mt-8" title="Product Specifications">
        {!loading &&
        product &&
        product.specification &&
        specifications[0].title ? (
          <>
            <div className="">
              {specifications &&
                specifications.map((e) =>
                  e.title && e.description ? (
                    <Row className="border border-solid border-slate-200">
                      <Col span={12} className="border-r border-slate-200 p-2">
                        <div className=" flex items-center h-full">
                          {e.title}
                        </div>
                      </Col>
                      <Col span={12} className="border-r border-slate-200 p-2">
                        <div className=" flex items-center h-full">
                          {e.description}
                        </div>
                      </Col>
                    </Row>
                  ) : null
                )}
            </div>
          </>
        ) : (
          ""
        )}
      </Card>

      <Modal
        title="Modal"
        visible={modalVisible}
        onOk={handleImageDelete}
        onCancel={hideModal}
        okButtonProps={{ type: "danger" }}
        okText="Delete Image"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete the image ?</p>
      </Modal>
    </>
  );

  function Images(e) {
    return (
      <div
        key={e.id}
        style={{
          position: "relative",
        }}
      >
        <Image
          width={"75%"}
          rootClassName="mr-5"
          // style={{ border: "1px solid #eee" }}
          src={`https://backend.dzone22.com${e.image}`}
        />{" "}
        {/* <DeleteOutlined
          onClick={() => {
            setDeleteId(e.id);
            setModalVisible(true);
          }}
          style={{
            position: "absolute",
            background: "rgba(238,238,238,.68)",
            cursor: "pointer",
            padding: 10,
            borderRadius: 500,
            top: 5,
            right: 25,
          }}
        /> */}
      </div>
    );
  }
}

export default ViewProduct;
