import { Badge, Button, Row, Space, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { orders } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";

function OrdersList() {
  const [productsList, setProductsList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      return await orders
        .get(localStorage.getItem("store"))
        .then((res) => {
          setLoading(false);
          console.log(res);
          setOrdersList(
            res.data.filter((order) => order.status !== "delivered")
          );
        })
        .catch((err) => {
          createAlert("error", err.message);
        });
    }
    getData();
  }, []);

  const handleColor = (status) => {
    switch (status) {
      case "accepted":
        return "#e2e8f0";
        break;

      case "onprocess":
        return "#bae6fd";
        break;

      case "on_the_way":
        return "#fef3c7";
        break;

      case "delivered":
        return "#bbf7d0";
        break;

      case "cancelled":
        return "#fecaca";
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: "ID",
      key: 5,
      dataIndex: "id",
    },
    {
      title: "Date",
      key: 0,
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>{moment(record.timestamp).format("YYYY-MM-DD")}</Space>
          </Row>
        </Space>
      ),
    },
    {
      title: "Items",
      key: 1,
      render: (text, record) => <div>{record.quantity}</div>,
    },
    {
      title: "Total Price",
      dataIndex: "total",
      key: 2,
      sorter: (a, b) =>
        new Date(a.registration_date) - new Date(b.registration_date),
      sortDirections: ["descend"],
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>₹ {text}</Space>
          </Row>
        </Space>
      ),
    },
    {
      title: "Customer",
      key: 0,
      sorter: (a, b) => a.username.length - b.username.length,
      sortDirections: ["ascend"],
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>{record.username}</Space>
          </Row>
        </Space>
      ),
    },

    {
      title: "Payment type",
      dataIndex: "order_type",
      key: 3,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["ascend"],

      key: 4,
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>
              <Badge
                count={text}
                className="site-badge-count-109"
                style={{
                  color: "black",
                  backgroundColor: "#e5e7eb",
                }}
              />
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: "",
      key: 4,
      render: (text, record) => (
        <Space size="middle">
          <Row>
            <Space>
              <Button
                onClick={() => {
                  navigate(`/orders/view?id=${record.id}`);
                }}
              >
                Show Details
              </Button>
            </Space>
          </Row>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageBreadcrumb items={[{ title: "Orders" }]} />
      <div className="d-flex justify-space-between align-content-center align-center my-5">
        <h6 className="font-bold text-xl"> Manage Orders </h6>
      </div>

      <Table
        scroll={{ x: "500px" }}
        loading={loading}
        pagination={true}
        dataSource={ordersList}
        rowKey={"id"}
        columns={columns}
      />
    </>
  );
}

export default OrdersList;
