import { Link, Outlet, useNavigate } from "react-router-dom";
import { Layout, Menu, Button, Dropdown, notification, Grid } from "antd";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  VideoCameraOutlined,
  MenuFoldOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
// import logo from "../assets/images/logo.png";
import { useEffect, useState } from "react";
import { regInBackend, user } from "../services/vendor.service";
import OneSignal from "react-onesignal";

import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";

const { Header, Content, Footer, Sider } = Layout;
const { useBreakpoint } = Grid;

function DashboardLayout({ route }) {
  const navigate = useNavigate();
  const screen = useBreakpoint();
  const [siderHidden, setSiderHidden] = useState(false);
  const [contentLeftMargin, setContentLeftMargin] = useState(250);
  const [contentSpace, setContentSpace] = useState("");
  const largeScreen = screen.md || screen.lg || screen.xl || screen.xxl;

  const OneSignalInit = () => {
    window["plugins"].OneSignal.setAppId(
      "b3a2fa00-d8a1-4864-b854-288bf8548fe8"
    );
    window["plugins"].OneSignal.setNotificationOpenedHandler(function (
      jsonData
    ) {
      console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
    });

    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(
      function (accepted) {
        console.log("User accepted notifications: " + accepted);
      }
    );
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      OneSignalInit();
      window["plugins"].OneSignal.getDeviceState(async (state) => {
        console.log(state.userId);
        await regInBackend(state.userId).then((res) => {
          console.log("push token response", res);
        });
      });
    } else {
      OneSignal.init({
        appId: "b3a2fa00-d8a1-4864-b854-288bf8548fe8",
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerPath: "/",
      }).then(() => {
        setInitialized(true);
        OneSignal.getUserId(async (e) => {
          localStorage.setItem("device_token", e);
          await regInBackend(e).then((res) => {
            console.log("push token response", res);
          });
        });
        OneSignal.showSlidedownPrompt().then(() => {
          console.log("one signal is booming!");
        });
      });
    }
  }, []);

  const get_vendor_details = async () => {
    await user.get().then((res) => {
      console.log(res);
      localStorage.setItem("user", JSON.stringify(res));
      localStorage.setItem("store", res?.user_vendor[0]?.id);
    });
  };

  useEffect(() => {
    get_vendor_details();
  }, []);

  //Screen checks for small display
  useEffect(() => {
    if (!siderHidden && !largeScreen) {
      setContentSpace("hidden");
    } else setContentSpace("");
  }, [siderHidden, screen]);

  const hideMenu = () => {
    if (largeScreen) {
      setContentLeftMargin(contentLeftMargin === 250 ? 0 : 250);
    } else {
      setContentSpace(" bg-");
    }

    setSiderHidden(!siderHidden);
  };

  //Dropdown menu + Logout functionality
  //testing

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    openNotification("success", "Logout Successful");
    navigate("login");
  };
  //Notification
  const openNotification = (type, message) => {
    if (type === "error") {
      notification.error({
        message: "Error",
        description: `${message}`,
        position: "top",
      });
    }
    if (type === "success") {
      notification.success({
        message: "Success",
        description: `${message}`,
        position: "top",
      });
    }
  };
  const dropdDownMenu = (
    <Menu>
      <Menu.Item key={1}>
        <span
          onClick={() => {
            navigate("/edit-profile");
          }}
        >
          My Account
        </span>
      </Menu.Item>
      <Menu.Item key={2}>
        <span onClick={handleLogout}> Logout </span>
      </Menu.Item>
    </Menu>
  );
  const items = [
    { label: <Link to="/">Dashboard</Link>, key: "1", icon: <UserOutlined /> },
    {
      label: <Link to="/products">Manage Products</Link>,
      key: "2",
      icon: <UploadOutlined />,
    },
    {
      label: <Link to="/orders">Manage Orders</Link>,
      key: "3",
      icon: <CloudOutlined />,
    },
    { label: "Alerts", key: "4", icon: <TeamOutlined /> },
  ];

  // --> Starts push notification code //
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    OneSignal.init({
      appId: "b3a2fa00-d8a1-4864-b854-288bf8548fe8",
      allowLocalhostAsSecureOrigin: true,
      serviceWorkerPath: "/",
    }).then(() => {
      setInitialized(true);
      OneSignal.getUserId(async (e) => {
        localStorage.setItem("device_token", e);
        await regInBackend(e).then((res) => {
          console.log("push token responsve", res);
        });
      });
      OneSignal.showSlidedownPrompt().then(() => {
        console.log("one signal is booming!");
      });
    });
  }, []);
  // Ends push notification code <-- //

  return (
    <>
      <Layout hasSider>
        <Sider
          trigger={null}
          breakpoint="lg"
          collapsed={siderHidden}
          collapsedWidth={0}
          width={250}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            background: "white",
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 100,
          }}
          onCollapse={(collapsed, type) => {
            setSiderHidden(true);
            setContentLeftMargin(0);
            setContentSpace("");
          }}
        >
          <div className="logo flex justify-between" style={{ padding: 20 }}>
            <h1 style={{ fontWeight: "bolder", fontSize: 28 }}> DZone22 </h1>
            <Button
              type="text"
              size="large"
              hidden={contentSpace !== "hidden"}
              onClick={hideMenu}
              icon={<MenuFoldOutlined />}
            ></Button>
          </div>
          <Menu
            mode="inline"
            onClick={() => {
              if (!largeScreen) hideMenu();
            }}
            items={items}
            defaultSelectedKeys={["1"]}
          />
        </Sider>
        <Layout
          className="site-layout"
          style={{
            marginLeft: contentLeftMargin,
            height: "calc(100vh - 70px)",
          }}
        >
          {contentSpace === "hidden" ? (
            <div
              onClick={hideMenu}
              className="z-20 fixed bg-slate-700 w-screen h-screen opacity-70 "
            />
          ) : null}
          <Header
            className="border-b border-slate-100 mb-4"
            style={{
              padding: "0 20px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className={
                contentSpace === "hidden" ? "invisible" : "flex-row-center"
              }
            >
              <Button
                type="text"
                size="large"
                onClick={hideMenu}
                icon={<MenuFoldOutlined />}
              ></Button>
              <h2 className="mb-0 ml-3 whitespace-nowrap">Vendor Dashboard</h2>
            </div>

            <Dropdown overlay={dropdDownMenu}>
              <Button
                shape="circle"
                type="primary"
                icon={<UserOutlined />}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              ></Button>
            </Dropdown>
          </Header>

          <Content style={{ padding: 10, overflowY: "auto" }}>
            <div
              // className={`${contentSpace} m-4`}

              style={{ paddingLeft: 24, paddingRight: 24 }}
            >
              <Outlet />
            </div>
          </Content>

          <Footer
            style={{
              position: "fixed",
              bottom: 0,
              zIndex: 1,
              width: "100%",
              background: "white",
            }}
          >
            Dzone ©2022. Created by Kingwizard Techsolutions Pvt. Ltd.
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}

export default DashboardLayout;
