import {
  CheckCircleFilled,
  CloseOutlined,
  DropboxCircleFilled,
  ExclamationCircleOutlined,
  HistoryOutlined,
  DollarCircleOutlined,
  HomeFilled,
  TagOutlined,
  TagFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Alert, Col, Modal, Row, Select, Spin, Steps } from "antd";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { orders } from "../../services/vendor.service";
import { createAlert } from "../../utilities/alert";

import { useReactToPrint } from "react-to-print";

const { Option } = Select;
const { Step } = Steps;
const { confirm } = Modal;

let order_texts = {
  packed: "Your order has been packed, awaiting delivery partner confirmation.",
  picked: "Your order has been picked up by delivery partner.",
  out_for_delivery: "Your order is out for delivery.",
  delivered: "Your order has been successfully delivered.",
  cancelled: "Your order has been cancelled.",
};

function OrderDetails() {
  const componentRef = useRef();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderDetails, setOrderDetails] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [orderTrackingModal, setOrderTrackingModal] = useState(false);

  const showPromiseConfirm = (text, status, id) => {
    confirm({
      title: `Are you sure you want to do this?`,
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: `You will not be able to reverse the action.`,
      onOk() {
        return new Promise((resolve, reject) => {
          changeStatus(status, id).then((res) => {
            if (res.success) {
              resolve();
            } else {
              reject();
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const changeStatus = (status, order_id) => {
    orders.changeStatus({ status: status, order_id: order_id }).then((res) => {
      console.log(res);
      if (res.success) {
        getOrderData();
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const getOrderData = useCallback(async () => {
    setLoading(true);
    await orders.getSingle(+searchParams.get("id")).then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        setOrderDetails(res.data);
      } else {
        createAlert("error", "Order Not Found.");
      }
    });
  }, [searchParams]);

  useEffect(() => {
    setLoading(true);
    getOrderData();
  }, [getOrderData]);

  return (
    <>
      <Spin spinning={loading}>
        {orderDetails && !loading ? (
          <>
            <PageBreadcrumb
              items={[
                { link: "/orders", title: "Orders" },
                { title: "Order Detail" },
              ]}
            />

            <Row gutter={20} className="mt-8">
              <Col lg={24}>
                <div
                  ref={componentRef}
                  className="bg-white overflow-hidden rounded-lg border mb-4 border-gray-200 shadow-sm"
                >
                  <h1 className="font-medium bg-slate-50 text-md p-3 border-b border-slate-300 flex items-center">
                    <TagOutlined className="mr-3" /> Order Details
                  </h1>

                  <div className="overflow-x-auto relative">
                    <table className="w-full text-sm text-left ">
                      <tbody>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Order ID
                          </th>
                          <td className="py-4 px-6">{orderDetails.id}</td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Status
                          </th>
                          <td className="py-4 px-6 capitalize">
                            {orderDetails.status}
                          </td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Date
                          </th>
                          <td className="py-4 px-6">
                            {moment(orderDetails.timestamp).format(
                              "YYYY-MM-DD || hh:mm A"
                            )}
                          </td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Product ID
                          </th>
                          <td className="py-4 px-6">
                            {orderDetails.product.id}
                          </td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Product Name
                          </th>
                          <td className="py-4 px-6">
                            {orderDetails.product.title}
                          </td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Quantity
                          </th>
                          <td className="py-4 px-6">{orderDetails.quantity}</td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Payment Mode
                          </th>
                          <td className="py-4 px-6 capitalize">
                            {orderDetails.order_type}
                          </td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Sub Total
                          </th>
                          <td className="py-4 px-6">
                            {parseInt(orderDetails.sub_total).toLocaleString(
                              "hi",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </td>
                        </tr>

                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Tax total
                          </th>

                          <td className="py-4 px-6">
                            {parseInt(orderDetails.tax_total).toLocaleString(
                              "hi",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </td>
                        </tr>

                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            Total Amount
                          </th>

                          <td className="py-4 px-6 font-bold">
                            {parseInt(orderDetails.total).toLocaleString("hi", {
                              style: "currency",
                              currency: "INR",
                            })}
                          </td>
                        </tr>
                        <tr className="bg-white border-b ">
                          <th
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                          >
                            OTP For delivery partner
                          </th>

                          <td className="py-4 px-6 font-bold">
                            {orderDetails.vendor_otp}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <p className="mt-3 font-medium text-lg text-slate-400 normal-case">
                  {order_texts[orderDetails.status]}
                </p>

                <div className="flex mt-5">
                  {orderDetails.status === "accepted" ? (
                    <>
                      <button
                        className="
                    flex items-center justify-center
                    text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800
                    disabled:cursor-not-allowed
                    disabled:bg-sky-900 
                    disabled:text-slate-300"
                        disabled={orderDetails.status !== "accepted"}
                        onClick={() => {
                          showPromiseConfirm(
                            "Packed",
                            "packed",
                            orderDetails.id
                          );
                        }}
                      >
                        Pack the order
                      </button>
                      <button
                        className="
                    flex items-center justify-center
                    text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  focus:outline-none 
                    disabled:cursor-not-allowed
                    disabled:bg-sky-900 
                    disabled:text-slate-300"
                        disabled={orderDetails.status !== "accepted"}
                        onClick={() => {
                          showPromiseConfirm(
                            "Cancelled",
                            "cancelled",
                            orderDetails.id
                          );
                        }}
                      >
                        Cancel Order
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      className="
                  flex items-center
                  text-gray-900 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  "
                      onClick={() => {
                        setOrderTrackingModal(true);
                      }}
                    >
                      <HistoryOutlined className="mr-2" />
                      Show Updates History
                    </button>
                  )}
                </div>
                <button
                  type="button"
                  className="
                  flex items-center
                  text-gray-900 border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  "
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  {/* <HistoryOutlined className="mr-2" /> */}
                  Print Label
                </button>
              </Col>
            </Row>
          </>
        ) : loading === false ? (
          <Alert type="warning" message="Order not found." />
        ) : (
          ""
        )}
      </Spin>

      <Modal
        title="Order Tracking Details"
        footer={false}
        onOk={() => {
          setOrderTrackingModal(false);
        }}
        onCancel={() => {
          setOrderTrackingModal(false);
        }}
        visible={orderTrackingModal}
      >
        <div className=" bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
          <h1 className="font-medium text-md p-3 bg-slate-50 border-b border-slate-300 flex items-center">
            <TagOutlined className="mr-2" /> Order Tracking
          </h1>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left text-gray-500 ">
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Accepted
                  </th>
                  <td className="py-4 px-6">
                    {moment(orderDetails?.timestamp).format(
                      "YYYY-MM-DD --- hh:mm A"
                    )}
                  </td>
                </tr>

                {orderDetails?.order_status.map((e) => {
                  return (
                    <tr key={e.id} className="bg-white border-b ">
                      <th
                        scope="row"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                      >
                        {e.status}
                      </th>
                      <td className="py-4 px-6">
                        {moment(e.timestamp).format("YYYY-MM-DD --- hh:mm A")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OrderDetails;
