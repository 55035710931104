import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";

import { api_login } from "../services/auth.service";
import user, { regInBackend } from "../services/vendor.service";
import OneSignal from "react-onesignal";

import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";

function Login(props) {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [initialised, setInitialized] = useState(false);

  const OneSignalInit = () => {
    window["plugins"].OneSignal.setAppId(
      "b3a2fa00-d8a1-4864-b854-288bf8548fe8"
    );
    window["plugins"].OneSignal.setNotificationOpenedHandler(function (
      jsonData
    ) {
      console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
    });

    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(
      function (accepted) {
        console.log("User accepted notifications: " + accepted);
      }
    );
  };

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      OneSignalInit();
      window["plugins"].OneSignal.getDeviceState(async (state) => {
        console.log(state.userId);
        await regInBackend(state.userId).then((res) => {
          console.log("push token response", res);
        });
      });
    } else {
      OneSignal.init({
        appId: "b3a2fa00-d8a1-4864-b854-288bf8548fe8",
        allowLocalhostAsSecureOrigin: true,
        serviceWorkerPath: "/",
      }).then(() => {
        setInitialized(true);
        OneSignal.getUserId(async (e) => {
          localStorage.setItem("device_token", e);
          await regInBackend(e).then((res) => {
            console.log("push token response", res);
          });
        });
        OneSignal.showSlidedownPrompt().then(() => {
          console.log("one signal is booming!");
        });
      });
    }
  }, []);

  const onFinish = (values) => {
    setLoading(true);

    let payload;

    let device_token_anroid;

    if (Capacitor.isNativePlatform()) {
      window["plugins"].OneSignal.getDeviceState(async (state) => {
        console.log(state.userId);
        device_token_anroid = state.userId;
      });
    }

    if (localStorage.getItem("device_token")?.length > 0) {
      payload = {
        username: values.username,
        password: values.password,
        device_token: localStorage.getItem("device_token"),
        role: "vendor",
      };
    } else if (Capacitor.isNativePlatform()) {
      payload = {
        username: values.username,
        password: values.password,
        role: "vendor",
        device_token: device_token_anroid,
      };
    } else {
      payload = {
        username: values.username,
        password: values.password,
        role: "vendor",
      };
    }

    api_login(payload).then(async (res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        // console.log(res);
        openNotification("success", "Logged In");
        localStorage.setItem("token", res.token);
        navigate("/");
      } else {
        openNotification("error", res.message);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    openNotification("error", "Error logging in.");
  };

  //Notification
  const openNotification = (type, message) => {
    if (type === "error") {
      notification.error({
        message: "Error",
        description: `${message}`,
        placement: "top",
      });
    }
    if (type === "success") {
      notification.success({
        message: "Success",
        description: `${message}`,
        placement: "top",
      });
    }
  };
  return (
    <>
      <div className="login-wrapper flex-row-center" style={{ height: "100%" }}>
        <div className="container">
          <div className="login-container">
            <div className=" flex justify-center">
              <h1 className="text-left text-3xl font-extrabold mb-5">Dzone </h1>
            </div>
            {/* <div className=" flex justify-center"> */}
            {/* <h1 className="text-left text-slate-800 text-xl font-bold mb-5">
              Vendor Login
            </h1> */}
            {/* </div> */}
            <div className="card">
              {/* <h1> Vendor Login. </h1> */}

              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Vendor Mobile number"
                  className="hide-astricks"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Enter Password"
                  className="hide-astricks"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <span
                  className="mb-6 block ant-btn-link"
                  onClick={() => {
                    navigate("/forget-password");
                  }}
                >
                  Forgot password
                </span>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    className="mt-4"
                    loading={loading}
                  >
                    Sign In
                    <RightCircleOutlined />
                  </Button>
                  <Button
                    htmlType="submit"
                    className="mt-4 w-100"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Register as new vendor
                    <RightCircleOutlined />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
