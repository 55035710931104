import httpClient from "./config";

export const get_shopCategories = async () => {
  return httpClient
    .get(`/store_category/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const api_submitShop = async (payload) => {
  return httpClient
    .post(`/user/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const user = {
  get: async (payload = {}) => {
    return httpClient
      .get(`/user/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  changePassword: async (payload) => {
    return httpClient
      .put(`/user/?change_password=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  edit_user: async (payload) => {
    return httpClient
      .put(`/user/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const store = {
  get_categories: async (payload) => {
    return httpClient
      .get(`/category/?storeId=${localStorage.getItem("store")}`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  add_category: async (payload) => {
    return httpClient
      .post(`/category/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  change_status: async (status, id, store_id) => {
    let s =
      status.toString().charAt(0).toUpperCase() + status.toString().slice(1);
    return httpClient
      .put(`/category/?status=${s}`, {
        store_id: store_id,
        category_id: id,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const branches = {
  get: async () => await httpClient.get(`/master/branch/`),
  getSingle: async (payload) => {
    return httpClient
      .get(`/master/branch/?id=${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  create: async (payload) => {
    return httpClient
      .post(`/master/plans/?branch_registration=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (id, status) => {
    return httpClient
      .put(`/master/branch/?id=${id}`, { status: status })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

// Digital subscription
export const digitalSubscription = {
  get: async (payload) => {
    return httpClient
      .get(`/master/vendor_registration/?digit_store=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  pruchase: async (payload) => {
    return httpClient
      .post(`/master/vendor_registration/?digit_store=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (id, status) => {
    return httpClient
      .put(`/master/branch/?id=${id}`, { status: status })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const offers = {
  get: async () => await httpClient.get(`/product/offer/`),
  create: async (payload) => {
    return httpClient
      .post(`/product/offer/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (id, status) => {
    return httpClient
      .put(`/master/branch/?id=${id}`, { status: status })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const products = {
  get: async (store_id) => {
    return httpClient
      .get(`/product/?store_id=${store_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  create: async (payload) => {
    return httpClient
      .post(`/product/?product=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  add_stocks: async (payload) => {
    return httpClient
      .post(`/product/?add_stock=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  edit: async (payload) => {
    return httpClient
      .put(`/product/?product=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getCategories: async (payload) => {
    return httpClient
      .get(`/product/?category_list=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getSingle: async (payload) => {
    return httpClient
      .get(`/product/?product_id=${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteImage: async (imageId) => {
    return httpClient
      .post(`/product/?product_image=true&delete_image=${imageId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  uploadImages: async (payload) => {
    return httpClient
      .post(`/product/?product_image=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (payload) => {
    return httpClient
      .put(`/product/?status=active`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStockStatus: async (payload) => {
    return httpClient
      .put(`/product/?status=in_stock`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

// Service API's
export const services = {
  get: async (payload) => {
    return httpClient
      .get(`/product/service/?service_list=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  create: async (payload) => {
    return httpClient
      .post(`/product/service/?service=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  edit: async (payload) => {
    return httpClient
      .put(`/product/?product=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getCategories: async (payload) => {
    return httpClient
      .get(`/product/service/?category_list=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getSingle: async (payload) => {
    return httpClient
      .get(`/product/service/?service_id=${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteImage: async (imageId) => {
    return httpClient
      .post(`/product/?product_image=true&delete_image=${imageId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  uploadImages: async (payload) => {
    return httpClient
      .post(`/product/?product_image=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  createSlot: async (payload) => {
    return httpClient
      .post(`/product/slot/?create=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getSlots: async (payload) => {
    return httpClient
      .get(`/product/slot/?service=${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const wallet = {
  get: async () => {
    return httpClient
      .get(`/master/wallet/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const coupons = {
  get: async () => {
    return httpClient
      .get(`/product/coupen/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  purchase: async (payload) => {
    return httpClient
      .post(`/product/coupen/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const api_getCategories = async () => {
  return httpClient
    .get("/master/plans/")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const api_checkMobile = async (payload) => {
  return await httpClient
    .put(`/user/?mobile_number=${payload}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const api_createBranch = async (payload) => {
  return httpClient
    .post(`/master/plans/?branch_registration=true`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const orders = {
  get: async () => {
    return httpClient
      .get(`/order/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (payload) => {
    return httpClient
      .put(`/order/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getSingle: async (id) => {
    return httpClient
      .get(`/order/?order_id=${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const regInBackend = async (e) => {
  return httpClient
    .post("/pushtoken/", {
      device_token: e,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

// refactoring the code =>

export const api = {
  get_cities: async () => {
    return await httpClient.get(`/city/`);
  },
  get_dashboard: async () => {
    return await httpClient.get(`/dashboard/`);
  },
};
