import React, { useState } from "react";
import { Row, Col, Card } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import Header from "../components/Header";
import { useEffect } from "react";
import { api } from "../services/vendor.service";

function Home() {
  const username = JSON.parse(localStorage.getItem("user"));
  console.log(username);

  const [data, setData] = useState({});

  const getData = () => {
    api.get_dashboard().then((res) => {
      console.log(res.data);
      setData(res.data.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* <Header title={`Welcome ${username.first_name}.`} /> */}
      <div>
        <Row>

          <Col lg={8} xs={24} sm={24} className="pl-0 pa-4">

            <Card style={{ width: "100%", borderRadius: 5 }}>
              <Row>
                <Col span={6}>
                  <div className="flex-col-center h-100">
                    <div
                      className="card-icon flex-row-center"
                      style={{
                        width: "60px",
                        height: "60px",
                        textAlign: "center",
                        lineHeight: "60px",
                        background: "#1890ff",
                        borderRadius: 5,
                      }}
                    >
                      <LineChartOutlined
                        style={{
                          color: "white",
                          fontWeight: 600,
                          fontSize: 28,
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={18} className="pl-5">
                  <h2
                    className="d-flex justify-space-between align-center w-100 mb-0"
                    style={{ color: "#1890ff", fontWeight: 400, fontSize: 16 }}
                  >
                    Total Products
                  </h2>
                  <h2
                    style={{
                      fontSize: 26,
                      fontWeight: 600,
                      marginBottom: 0,
                      color: "#151D3B",
                    }}
                  >
                    {data.product_count}
                  </h2>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col lg={8} xs={24} sm={24} className="pl-0 pa-4">

            <Card style={{ width: "100%", borderRadius: 5 }}>
              <Row>
                <Col span={6}>
                  <div className="flex-col-center h-100">
                    <div
                      className="card-icon flex-row-center"
                      style={{
                        width: "60px",
                        height: "60px",
                        textAlign: "center",
                        lineHeight: "60px",
                        background: "#1890ff",
                        borderRadius: 5,
                      }}
                    >
                      <LineChartOutlined
                        style={{
                          color: "white",
                          fontWeight: 600,
                          fontSize: 28,
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={18} className="pl-5">
                  <h2
                    className="d-flex justify-space-between align-center w-100 mb-0"
                    style={{ color: "#1890ff", fontWeight: 400, fontSize: 16 }}
                  >
                    Total Orders
                  </h2>
                  <h2
                    style={{
                      fontSize: 26,
                      fontWeight: 600,
                      marginBottom: 0,
                      color: "#151D3B",
                    }}
                  >
                    {data.order_count}
                  </h2>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
